// Assuming that the default font-size is 14px

// Some of this variables will be found into the variables of the dashbord or bootstrap
// and some others will be only to make the custom styles of this application. 

$font-family-base: 'Poppins', sans-serif;
$font-family-alt: 'Poppins', sans-serif;

$default-font-size: 14px;

$font-size-base: 1rem;

$h1-font-size: 2.429rem; // 34px
$h1-font-weight: 600;

$h2-font-size: 2.285717rem; // 32px
$h2-font-weight: 500;

$h3-font-size: 1.71428rem; // 24px
$h3-font-weight: 300;

$h4-font-size: 1.4285rem; // 20px
$h4-font-weight: $font-weight-base;

$small-font-size: .714rem; // 10px
$small-font-weight: $font-weight-base; 

$base-blue: #5D70E0;

$padding-input-vertical: 0.5rem;
$padding-input-horizontal: 1rem;