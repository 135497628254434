@import "forms";


.card.card-timeline .timeline > li > .timeline-panel {
  box-shadow: none !important;
}


.spinner { margin: 50px auto; text-align: center; }
.spinner > div { width: 30px; height: 30px; background-color: #4D67FF; display: inline-block; -webkit-animation: sk-bouncedelay 2s infinite ease-in-out both; animation: sk-bouncedelay 2s infinite ease-in-out both; margin-right: 5px; }

.spinner .bounce1 { -webkit-animation-delay: -0.50s; animation-delay: -0.50s; }
.spinner .bounce2 { -webkit-animation-delay: -0.40s; animation-delay: -0.40s; }
.spinner .bounce3 { -webkit-animation-delay: -0.30s; animation-delay: -0.30s; }
.spinner .bounce4 { -webkit-animation-delay: -0.20s; animation-delay: -0.20s; }
.spinner .bounce4 { -webkit-animation-delay: -0.10s; animation-delay: -0.10s; }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  20% {
    opacity: 0.70;
  }
  40% {
    opacity: 0.80;
  }
  60% {
    opacity: 0.90;
  }
}
