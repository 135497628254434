
input {
  padding-left: 32px !important;
}
.error {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #ff1721 !important;
  margin-top: 5px;
}

:deep(.iti__country-container) {
  right: auto !important;
  left: 0;
}
