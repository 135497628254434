.input-wrapper {
  position: relative;
}
.form-group.has-success .form-control {
  border-color: #4fce3e !important;
}
input.success-brdr {
  border-color: #4fce3e !important;
}
span.success-brdr {
  border-color: #4fce3e !important;
}
div.success-brdr {
  border-color: #4fce3e !important;
}
select.success-brdr {
  border-color: #4fce3e !important;
}
select.error-brdr {
  border-color: #ff151f !important;
}

span.success-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 10px;
}
span.success-icon i {
  font-style: normal;
  font-size: 16px;
  line-height: 1;
  color: #7ed321;
}
input.error-brdr {
  background: rgba(249, 249, 252, 0.48);
  border-color: #ff151f !important;
}
textarea.error-brdr {
  background: rgba(249, 249, 252, 0.48);
  border-color: #ff151f !important;
}
div.error-brdr {
  background: rgba(249, 249, 252, 0.48);
  border-color: #ff151f !important;
}
span.error-brdr {
  background: rgba(249, 249, 252, 0.48);
  border-color: #ff151f !important;
}
span.error-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1.5;
}
span.error-icon i {
  font-style: normal;
  font-size: 16px;
  line-height: 1;
  color: #ff151f;
}
i.error-msg {
  font-weight: 400;
  font-size: 11px;
  color: #ff1721;
  letter-spacing: 0;
  font-style: normal;
}
.diy-tooltip {
  position: relative;
}
.diy-tooltip span {
  position: absolute;
  left: 0px;
  top: -30px;
  font-size: 12px;
}
i.required-field {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #d0021b;
  border-radius: 50%;
  vertical-align: middle;
  margin: 9px 4px 0px 5px;
}
span.details-title {
  font-size: 14px;
  color: #82879e;
  line-height: 35px;
}
span.details-text {
  font-size: 16px;
  color: #24262b;
  letter-spacing: 0;
  line-height: 35px;
}
div.owner-detail {
  display: inline-block;
  width: 100%;
}
div.owner-detail h3 {
  float: left;
  width: auto;
}
div.owner-detail .remove-icon {
  float: right;
  width: auto;
}
div.remove-icon a i {
  font-style: normal;
  font-size: 18px;
  line-height: 1;
  color: #82879e;
}
div.remove-icon a i:hover {
  color: red;
}
.add-owner-wrap {
  display: inline-block;
  width: 100%;
  text-align: right;
}
.add-owner-wrap .add-owner {
  padding: 10px 20px;
  text-align: center;
  border: 2px solid green;
  border-radius: 0.25rem;
  background-color: green;
  margin: 0 0 20px; /*decreased 30px*/
  display: inline-block;
}

.add-owner {
  padding: 12px 54px;
  text-align: center;
  border: 2px solid green;
  border-radius: 0.25rem;
  background-color: green;
}

.add-owner span {
  font-size: 15px;
  color: #fff;
  line-height: 23px;
  font-weight: 300;
}
.add-owner span i {
  font-size: 15px;
  margin-right: 15px;
}

input[type='email']:-ms-input-placeholder,
input[type='text']:-ms-input-placeholder,
input[type='number']:-ms-input-placeholder,
input[type='date']:-ms-input-placeholder {
  /* IE 10+ */
  color: #bec1cd;
  font-size: 16px;
  font-weight: lighter;
  line-height: 23px;
}

input[type='email']:-moz-placeholder,
input[type='text']:-moz-placeholder,
input[type='number']:-moz-placeholder,
input[type='date']:-moz-placeholder {
  /* Firefox 18- */
  color: #bec1cd;
  font-weight: lighter;
}

input.form-control:focus,
textarea.form-control:focus {
  background: rgb(255, 255, 255) !important;
  border-color: rgb(103, 103, 103) !important;
  border-width: 1px !important;
}

.input-group-focus .input-group-prepend .input-group-text {
  border-color: rgb(77, 103, 255) !important;
}

select.form-control:not([size]):not([multiple]) {
}

input.form-control:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill,
input.form-control:-ms-autofill,
textarea:-ms-autofill,
select:-ms-autofill,
input.form-control:-moz-autofill,
textarea:-moz-autofill,
select:-moz-autofill,
input.form-control::-moz-autofill,
textarea::-moz-autofill,
select::-moz-autofill {
  background-color: rgba(96, 127, 255, 0.07) !important;
  border-color: #4d67ff !important;
}
select.form-control {
  padding: 6px 13px !important;
}
input.form-control,
select.form-control,
.custom-radio .custom-control-input ~ .custom-control-label {
  font-size: 1rem;
}
textarea.form-control {
  border: 1px solid #2b3553;
  border-radius: 7px;
  font-size: 1rem !important;
}
textarea.form-control:focus {
  border-width: 1px !important;
  border-left: unset !important;
  border-top: unset !important;
  border-right: unset !important;
  border: 1px solid black !important;
}
