@import '../../variables';

@mixin slider($name, $color, $height: 6px) {
  .slider-#{$name} {
    .el-slider__bar {
      height: $height;
      background-color: $color;
    }
    .el-tooltip {
      border: none;
    }
    .el-slider__button {
      &.hover,
      &:hover,
      &.dragging {
        background-color: darken($color, 10%);
      }
      background-color: $color;
      height: $height * 3;
      width: $height * 3;
    }
  }
}

@include slider('info', $info);
@include slider('primary', $primary);
@include slider('success', $success);
@include slider('warning', $warning);
@include slider('danger', $danger);
