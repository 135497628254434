@import '../../variables';

@mixin tag($type, $color) {
  .input-new-tag.input-#{$type} .el-input__inner {
    border-color: $color !important;
  }
  .el-tag,
  .el-tag.el-tag--#{$type} {
    .el-tag__close {
      color: white;
    }
    .el-tag__close:hover {
      background-color: white;
      color: $color;
    }
    background-color: $color;
    color: white;
  }
}
.el-tag {
  border-radius: 12px !important;
  margin-left: 10px;
  margin-bottom: 5px;
}
.input-new-tag {
  margin-left: 10px;
  width: 150px !important;
  height: $input-height;
  display: inline;
}

@include tag('info', $info);
@include tag('primary', $primary);
@include tag('success', $success);
@include tag('warning', $warning);
@include tag('danger', $danger);
