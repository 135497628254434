:root, html, body { 
  font-size: $default-font-size; 
  line-height: 24px;
}

h1, .h1 {
  font-size: $h1-font-size;
  font-weight: $h1-font-weight;
}

h2, .h2 {
  font-size: $h2-font-size;
  font-weight: $h2-font-weight;
}

h3, .h3 {
  color: $base-blue;
  font-size: $h3-font-size;
  font-weight: $h3-font-weight;
}

h4, .h4 {
  font-size: $h4-font-size;
  font-weight: $h4-font-weight;
}

small, .small {
  font-size: $small-font-size;
  font-weight: $small-font-weight;
}

// Set blue all the titles of cards that doesn't have the card-white class
.white-content {
  .card:not(.card-white) {
    .card-header .card-title,
    .card-body .card-title {
      color: $base-blue;
      font-weight: $h3-font-weight;
    }
  }
}