// Height values in vh

.h-100vh {
	height: 100vh !important;
}

@include media-breakpoint-down(md) {
	.mw-down-md-100 {
		max-width: 100%;
	}
}

@include media-breakpoint-up(md) {
	.mw-md-80 {
		max-width: 80%;
	}
}

@include media-breakpoint-up(lg) {
	.mw-lg-60 {
		max-width: 60%;
	}
}

@include media-breakpoint-up(xl) {
	.mw-xl-50 {
		max-width: 50%;
	}
}
