
.badge-label {
  font-size: 15px;
  position: relative;
  top: -1px;
}
.day {
  position: absolute;
  top: -40px;
  left: 65px;
}
