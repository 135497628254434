///theme///

///dark-mode///
body:not(.white-content) {
  color: #ffffffb3;

  input {
    background: $card-black-background;
    color: $white;

    &:disabled {
      background: #00000000;
    }
  }

  .stat-icon-image {
    filter: invert(100%) brightness(150%);
  }

  .fixed-header {
    & > tfoot {
      & > tr {
        & > td {
          background: $card-black-background;
          color: $white;
        }
      }
    }
  }

  .table {
    > thead > th,
    > thead + tbody > tr > td,
    > colgroup + tbody > tr > td {
      border-top: $table-border-width solid #555;
    }
  }

  .contextual-popover {
    background-color: #27293d;
    box-shadow: 0 0 3px #555;
  }

  .contextual-element-wrapper:hover {
    background-color: #1e1f2b;
  }

  .contextual-separator {
    border-bottom: 1px solid #555;
  }

  .contextual-element {
    & * {
      color: #fff !important;
    }
  }

  .form-control,
  .el-date-picker .el-input .el-input__inner,
  .form-group .el-input__inner {
    border-color: #555;
  }

  .select-filter .el-input > input {
    border-color: #555;
  }

  .el-select-dropdown {
    background-color: #27293d;
    box-shadow: 0 0 3px #555;
    border: 1px solid #555;

    & .el-select-dropdown__item {
      color: #fff !important;

      &.hover {
        background-color: #1e1f2b;
      }
    }
  }

  .fixed-header > table > tfoot > tr > td {
    color: #ffffffbf;
    background: $card-black-background;
  }

  table tbody {
    tr {
      &.selectedRow {
        background-color: #00000022;
      }

      &:hover {
        background-color: #00000011;
      }
    }

    td {
      &.actionable:hover {
        background-color: #00000022;
      }
    }
  }

  .dropdown-menu {
    background-color: #27293d;
    color: #fff;
    box-shadow: 0 0 3px #555;
    border: 1px solid #555;

    & .dropdown-item {
      &:hover {
        background-color: #1e1f2b;
      }
    }
  }

  .mx-datepicker-popup {
    background-color: #27293d;

    .mx-datepicker-footer {
      .mx-datepicker-btn-confirm:hover {
        border-color: var(--theme-primary-color) !important;
        color: var(--theme-primary-color) !important;
      }
    }
  }

  .mx-panel-date {
    td {
      &.today {
        &:hover {
          background-color: #1e1f2b !important;
        }

        &.inrange {
          background-color: #1e1f2b !important;
        }
      }
    }

    .cell:not(.today) {
      &.actived,
      &:hover {
        color: #ebebeb !important;
        background-color: #00000022 !important;
      }

      &.actived {
        background-color: var(--theme-primary-color) !important;
      }

      &.inrange {
        background-color: #00000033 !important;
        color: #fff !important;

        &:hover {
          background-color: #1e1f2b !important;
        }
      }

      &.disabled {
        color: #555 !important;
        background-color: #ffffff11 !important;
      }

      &.last-month {
        color: #555 !important;
      }

      &.cur-month {
        color: #fff !important;

        &.disabled {
          color: #555 !important;
        }
      }
    }
  }

  .background-color {
    background-color: #1e1e26;
  }

  .el-card {
    background-color: #27293d;
    border: 1px solid #555;
    color: #ffffffbb;
  }

  .el-table th.el-table__cell {
    background-color: #27293d;
  }

  .timeline-table .el-table__cell {
    border-color: #555 !important;
  }

  .form-control {
    background-color: #27293d;

    &:focus {
      background: #00000022 !important;
    }
  }

  .json-display .sticky {
    background-color: #27293d;
  }

  .vjs-tree-node {
    &.is-highlight {
      background-color: #1e1f2b;
    }

    &:hover {
      background-color: #1e1f2b;
    }
  }

  input.success-brdr {
    background: #00000011 !important;
  }

  .text-primary {
    color: var(--theme-primary-color) !important;
  }

  .select-primary.el-select .el-input input[disabled] {
    background-color: #00000022 !important;
    color: var(--theme-primary-color-lightness-4-saturation-5) !important;
  }

  .select-primary.el-select .el-input input {
    border-color: #555 !important;
  }

  .modal-body {
    p {
      color: #ffffffbb;
    }
  }

  .border-right {
    border-right: 0.0625rem solid #555 !important;
  }

  .select-merchant .el-select__tags .el-tag,
  .select-merchant .el-select__tags .el-tag.el-tag--info {
    background-color: #27293d !important;
    border-color: #555 !important;

    & .el-select__tags-text {
      color: #fff !important;
    }
  }

  .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    background-color: #00000044 !important;
  }

  .swal2-popup {
    background-color: #27293d !important;

    & .swal2-title {
      color: #fff !important;
    }
  }

  .el-tooltip__popper {
    background-color: #242538 !important;
  }

  .form-control[disabled] {
    color: #555 !important;
  }

  .comment-body {
    background-color: #1e1e25;

    &::after {
      border-top-color: #1e1e25;
    }
  }

  .c-json-view {
    background-color: #27293d;

    & .c-json-p:hover {
      background-color: #1e1f2b;
    }

    & .c-line-del {
      background-color: #5c2c2c;
    }

    & .c-line-add {
      background-color: #1b864a;
    }
  }

  .success-border {
    .el-input__inner {
      background-color: transparent !important;
    }
  }

  #main-card {
    &:hover {
      background-color: #1e1f2b;
    }
  }

  .btn-remove {
    color: #555;
    border-color: #555;

    &:hover {
      color: var(--theme-primary-color) !important;
      border-color: var(--theme-primary-color) !important;
    }
  }

  .floating-arrows {
    .btn {
      background-color: #27293d;
      border-color: #555;
      color: #555;

      &:hover {
        background-color: #1e1f2b !important;
      }
    }
  }

  .action-button {
    color: #fff !important;
  }

  // .collapsable-item {
  //   .card-header::after {
  //     background: #ffffffa1 !important;
  //   }
  // }
}

///dark-mode///

#simple-password-input {
  .input-group-text {
    height: $input-height;
    border-color: rgba(29, 37, 59, 0.5) !important;
  }
}

a {
  color: var(--theme-primary-color-darker) !important;
}

.pagination.pagination-primary .page-item.active > .page-link {
  background-color: var(--theme-primary-color) !important;
  background-image: none !important;
  color: var(--theme-primary-color-contrast) !important;
}

.show > .btn-primary.dropdown-toggle {
  background-color: var(--theme-primary-color) !important;
  border-color: var(--theme-primary-color);

  &:hover {
    color: var(--theme-primary-color-contrast) !important;
  }
}

.white-content .card:not(.card-white) label:not(.btn) {
  color: var(--theme-primary-color-lightness-4-saturation-6);
}

.btn-simple {
  border-color: #555;
  color: #555;
}

.btn-primary,
.btn-info {
  background: var(--theme-primary-color) !important;
  background-image: none !important;
  &:not(.btn-simple) {
    color: var(--theme-primary-color-contrast, #ffffff) !important;
    border: 1px solid var(--theme-primary-color-lighter);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  &.dropdown-toggle {
    border-color: var(--theme-primary-color) !important;

    &:focus,
    &:active {
      color: var(--theme-primary-color-contrast) !important;
      background: var(--theme-primary-color);
      background-color: var(--theme-primary-color) !important;
      background-image: none !important;
    }

    &:hover {
      color: var(--theme-primary-color-contrast) !important;
    }

    &:active {
      color: var(--theme-primary-color);
    }
  }

  &.btn-simple {
    background: transparent !important;
    color: var(--theme-primary-color-darker);
    border-color: var(--theme-primary-color);

    &:hover,
    &:focus {
      color: var(--theme-primary-color-darker) !important;
      border-color: var(--theme-primary-color) !important;
    }

    &:active {
      color: var(--theme-primary-color-darker) !important;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background: var(--theme-primary-color);
    background-color: var(--theme-primary-color) !important;
    background-image: none !important;
  }

  &:active:hover,
  &:active:focus {
    background-color: var(--theme-primary-color) !important;
    background-image: none !important;
  }

  &:not(:disabled):not(.disabled):active {
    border-color: var(--theme-primary-color) !important;
  }
}

.select-merchant .el-input__inner {
  background: var(--theme-primary-color) !important;
  background-color: var(--theme-primary-color) !important;
  border-color: var(--theme-primary-color) !important;
}

.select-merchant .el-select__input {
  background: transparent !important;
}

.card-title {
  color: var(--theme-primary-color-darker) !important;
}

.upButton {
  color: var(--theme-primary-color-lightness-9-saturation-20) !important;
  text-shadow: 1px 1px var(--theme-primary-color) !important;
}

.downButton {
  color: var(--theme-primary-color-lightness-8-saturation-20) !important;
  text-shadow: 1px 1px var(--theme-primary-color) !important;
}

td > div > a {
  color: var(--theme-primary-color-darker) !important;
}

.el-tag--info {
  background-color: var(--theme-primary-color) !important;

  & .el-tag__close {
    color: var(--theme-primary-color-contrast) !important;

    &.el-icon-close {
      background-color: var(--theme-primary-color-lighter) !important;
    }
  }
}

.btn.btn-defaut.btn-sm {
  background-color: var(--theme-primary-color) !important;
  border-color: var(--theme-primary-color) !important;
  background: var(--theme-primary-color);
}

.nav-link {
  color: var(--theme-primary-color-contrast-opposite) !important;
  background-color: var(--theme-primary-color-lightness-8-saturation-7) !important;
}

.nav-link.active {
  color: var(--theme-primary-color-contrast) !important;
  background-color: var(--theme-primary-color) !important;
  border-color: var(--theme-primary-color) !important;
  background-image: none !important;
}

.vue-slider-process {
  background-color: var(--theme-primary-color) !important;
}

.mx-calendar-content .cell.actived {
  color: var(--theme-primary-color-contrast) !important;
  background-color: var(--theme-primary-color);
}

.mx-calendar-content .cell:hover {
  background-color: var(--theme-primary-color-lightness-9-saturation-18) !important;
}

.select-primary.el-select .el-input input {
  color: var(--theme-primary-color-lighter) !important;
}

.el-select .el-input.is-focus .el-input__inner,
.el-select .el-input.is-focus .el-input__inner:hover {
  background: rgba(96, 127, 127, 0.07) !important;
  border-color: rgb(103, 103, 103) !important;
  border-width: 1px !important;
}

.select-primary.el-select .el-input:hover input {
  border-color: var(--theme-primary-color) !important;
}

.select-primary.el-select-dropdown__item.selected,
.select-primary.el-select-dropdown__item.selected.hover {
  background-color: var(--theme-primary-color) !important;
  color: var(--theme-primary-color-contrast) !important;
}

.select-primary.el-select .el-input .el-input__icon {
  color: var(--theme-primary-color) !important;
}

.form-check-radio input[type='radio']:checked + .form-check-sign::before {
  border-color: var(--theme-primary-color) !important;
}

input[type='checkbox']:checked + .form-check-sign::before {
  background-color: var(--theme-primary-color) !important;
}

.form-check-radio input[type='radio']:checked + .form-check-sign::after {
  background-color: var(--theme-primary-color) !important;
  border-color: var(--theme-primary-color) !important;
}

.form-check-radio input[type='radio']:checked::after {
  background-color: var(--theme-primary-color) !important;
  border-color: var(--theme-primary-color) !important;
}

.badge-info {
  background-color: var(--theme-primary-color) !important;
  color: var(--theme-primary-color-contrast) !important;
}

.mx-panel-date td.today {
  color: var(--theme-primary-color) !important;
}

.mx-calendar-content .cell.inrange {
  background-color: var(--theme-primary-color-lightness-9-saturation-4) !important;
}

.card-collapse .card .card-header a[data-toggle='collapse'] i {
  color: var(--theme-primary-color) !important;
}

.text-primary {
  color: var(--theme-primary-color) !important;
}

///theme///

$gap-sizes: (
  1: 4px,
  2: 8px,
  3: 12px,
  4: 16px,
  5: 20px
);
.d-grid {
  display: grid;
  gap: map-get($gap-sizes, 4);
}
@for $i from 1 through 12 {
  .grid-columns-#{$i},
  .col-span-#{$i} {
    grid-template-columns: repeat($i, 1fr);
    grid-column: span $i;
  }
}
@for $i from 1 through 6 {
  .grid-rows-#{$i},
  .row-span-#{$i} {
    grid-template-rows: repeat($i, 1fr);
    grid-row: span $i;
  }
}
@each $number, $size in $gap-sizes {
  .gap-#{$number} {
    gap: $size;
  }
}

.vjs-value {
  &.vjs-value-string {
    color: #00bf9a !important;
  }

  // &.vjs-value-number {
  //   color: #FC526A !important;
  // }
}

.inline-label {
  display: flex;
  gap: 10px;
  align-items: center;
  text-align: center;
  justify-content: space-between;

  & .input-label {
    margin-bottom: 0;
    // flex: 1 0 auto;
  }

  & .input-wrapper {
    flex: 0 1 auto;
  }
}

.btn-reduced {
  padding: 4px 6px !important;
}

.page-card {
  border-radius: 0;
  box-shadow: none !important;
}

.el-select-dropdown.el-popper {
  z-index: 10061 !important;
  // min-width: fit-content !important;
}

.el-select .el-input {
  &:hover {
    .el-input__icon,
    input {
      color: black;
    }
  }
}

.timeline-wrapper {
  border-left: 1px solid gray;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  margin: 20px 0 20px 60px;
  position: relative;
  padding: 0 0 0 11px;
  list-style: none;
  text-align: left;

  & .timeline-item {
    position: relative;

    & > h3 {
      position: relative;
      top: -5px;
      color: var(--theme-primary-color-darker);
    }

    & p {
      display: inline-block;
      cursor: pointer;

      &:after {
        position: relative;
        float: left;
        box-shadow: 0 0 0 1px lightgray;
        background: lightgray;
        top: 9px;
        right: 0px;
        border-radius: 50%;
        height: 5px;
        width: 5px;
        margin-right: 15px;
        content: '';
      }
    }

    &:first-of-type > h3 {
      top: -12.2px;
      margin-bottom: -8.8px;
    }

    &:first-of-type p {
      &:last-of-type {
        margin-bottom: 10px;
      }
    }

    &:last-of-type > h3 {
      top: 13px;
      margin-bottom: 20px;
    }

    &:last-of-type,
    h3 {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }

    &:nth-last-child(2) > * {
      // padding-bottom: 0;
      &:first-of-type {
        margin-top: 10px;
      }

      &:last-of-type {
        margin-bottom: -10px;
      }
    }

    &:before,
    &:after {
      position: absolute;
      display: block;
    }

    &:first-of-type:before {
      top: -8px;
    }

    &:before {
      left: -160px;
      content: attr(left-value);
      text-align: right;
      min-width: 120px;
    }

    &:last-of-type:before {
      top: 18px;
    }

    & p:before {
      position: absolute;
      left: -160px;
      content: attr(left-value);
      text-align: right;
      min-width: 120px;
      color: lightgrey;
    }

    &:after {
      box-shadow: 0 0 0 1px lightgray;
      background: lightgray;
      left: -23.5px;
      border-radius: 50%;
      height: 5px;
      width: 5px;
      content: '';
      top: 10px;
    }

    &:first-of-type:after {
      top: 2px;
    }

    &:last-of-type:after {
      top: 27px;
    }

    & *[status='success'],
    &[status='success'] {
      &:after {
        box-shadow: 0 0 0 1px $green-success;
        background: $green-success;
      }
    }

    & *[status='failure'],
    &[status='failure'] {
      &:after {
        box-shadow: 0 0 0 1px $red-error;
        background: $red-error;
      }
    }

    & *[status='warning'],
    &[status='warning'] {
      &:after {
        box-shadow: 0 0 0 1px $orange-warning;
        background: $orange-warning;
      }
    }
  }

  & .check-item {
    &:after {
      font-weight: bolder;
      display: flex;
      color: white;
      top: 7px !important;
      content: attr(dot-content);
      font-size: 1rem;
      width: 22px;
      height: 21px;
      left: -25px;
      border-radius: 50%;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
}

.mx-datepicker-popup {
  z-index: 10000;
}

.balance-detail-modal {
  & > .modal-wrapper {
    max-width: 75vw;
  }
}

#balance-select-merchant {
  position: relative;
  margin-top: -15px;
  top: -11px;

  & .el-input__inner {
    border-radius: 6px;
    line-height: 34px;
    height: 34px !important;
    border-color: darkgray;
  }

  & .el-input__icon {
    width: 30px;
    line-height: 34px !important;
    color: gray;
  }

  & .el-tag.el-tag--info {
    color: var(--theme-primary-color-contrast) !important;
  }
}

.removed-bottom {
  & > .card-body {
    padding-bottom: 0;

    & > .card {
      margin-bottom: 0;
    }
  }
}

.removed-top {
  & > .card-body {
    padding-top: 0;
  }
}

.monitor-status {
  font-size: 12px;
  vertical-align: middle;
}

.monitor-success {
  color: green !important;
  font-size: 14px;
}

.monitor-failure {
  color: red !important;
  font-size: 14px;
}

.monitor-unknown {
  color: grey !important;
  font-size: 14px;
}

.account-tabs > div {
  margin-top: 12px;
  // margin-bottom: -25px;
}

.report-page-title {
  @include media-breakpoint-down('md') {
    margin-top: 0.25rem;
  }
}

.action-button {
  border: none;
  padding: 5px 4px;
  height: 30px;
  color: #000 !important;
}

.sweet-alert-cancel-btn {
  margin-right: 0.5rem;

  @include media-breakpoint-down('xs') {
    margin-right: 0.5rem;
  }
}

.el-ui-table {
  & div:nth-of-type(2) > table {
    margin-bottom: 0;
  }

  & .el-table__row {
    &.current-row > td {
      background-color: #dedede;
    }

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.w-50-md {
  @include media-breakpoint-up('md') {
    width: 50%;
  }
}

.select-filter {
  & .el-input {
    margin-top: -1px;

    > input {
      height: calc(1.5125rem + 2px);
      border-radius: 0.4285rem;
      font-size: 12px;
      border-color: rgba(29, 37, 59, 0.5);
      background-color: transparent;

      &:focus {
        background: rgba(96, 127, 255, 0.07) !important;
        border-color: #4d67ff !important;
        box-shadow: none;
      }

      &:hover {
        border-color: rgba(29, 37, 59, 0.5);
        cursor: default;
      }
    }

    &:hover .el-select__caret {
      color: #c0c4cc;
    }

    & .el-select__caret {
      font-size: 12px;
      width: 20px;
      height: 28px;
      line-height: 28px;
    }
  }

  & .el-select__tags {
    // max-width: 148px !important;
    & .el-tag {
      margin-top: 0;
      margin-bottom: 0;
      height: 22px;
      padding: 0 3px 0 3px;
      line-height: 20px;

      & .el-tag__close {
        right: -2px;
      }

      & .el-select__tags-text {
        text-transform: none;
      }

      color: var(--theme-primary-color-contrast);
    }
  }
}

.white-content {
  .label-switch {
    color: #344675;
  }
}

.label-switch {
  position: relative;
  left: -10px;
  top: -4px;
  font-size: 0.76rem;
  color: rgba(255, 255, 255, 0.6);

  &.label-right {
    left: 10px;
  }
}

.timeline-table {
  & .el-table__cell {
    // border-top: 1px solid #ebebeb;
    border-color: #ebebeb !important;
    border-bottom: none !important;
  }

  & .timeline-cell {
    & .cell {
      word-break: break-word !important;
    }
  }

  & th.amount-cell,
  td.amount-cell,
  td.custom-amount-cell {
    text-align: right;
  }

  & td.amount-cell,
  td.custom-amount-cell {
    @extend .numeric;
  }

  & .el-table__footer {
    font-weight: 700;

    & .el-table__cell {
      background-color: transparent;
    }

    & td {
      border: none;
      background-color: transparent;
    }

    & .timeline-cell {
      text-align: right;
    }
  }
}

.numeric {
  font-family: 'Open Sans', monospace;
}

.numeric-cell {
  @extend .numeric;
}

.mx-input {
  background-color: white;
}

.fees-form {
  & .reverse-checkbox {
    & span {
      &::before,
      &::after {
        top: 0 !important;
      }
    }
  }
}

.for-testing {
  display: none;
}

.vld-overlay.is-full-page {
  z-index: 2147483 !important;
}

.copy-button {
  height: 38px;
  margin-top: 25px;
  border: none;
}
.cross-rule:before {
  content: '\2716';
  color: red;
  padding-right: 9px;
  font-size: 12px;
}

.check-rule:before {
  content: '\2713';
  color: lightgreen;
  padding-right: 7px;
  font-size: 17px;
  font-weight: 800;
}

.check-rules-item {
  margin-left: 6px;

  & > span {
    display: flex;
    gap: 2px;
  }

  &:hover {
    cursor: pointer;
    font-weight: 900;
    color: var(--theme-primary-color-darker) !important;
    border-color: var(--theme-primary-color) !important;

    & > span:before {
      font-size: 18px;
    }
  }
}

.warning-rule:before {
  content: '\f071';
  padding-right: 7px;
  font-size: 11px;
  font-weight: 800;
  font-family: 'Font Awesome 6 Pro';
  color: yellow;
  padding: 5px;
  line-height: 6px;
  -webkit-clip-path: polygon(50% 0%, 11% 90%, 87% 90%);
  clip-path: polygon(50% 0%, 11% 90%, 87% 90%);
  display: inline-block;
  background-color: black;
}

.question-rule:before {
  content: '?';
  padding-left: 2px;
  padding-right: 7px;
  font-size: 12px;
}

.confirm-profile {
  & .section-title {
    font-size: 18px;
    font-weight: normal;
    color: var(--theme-primary-color-darker) !important;
    line-height: 25px;
  }

  & label {
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 0;

    & > span {
      color: #82879e !important;
    }
  }

  & span {
    color: black;
  }
}

.white-space-pre-line {
  white-space: pre-line;
}

.hide-text input {
  -webkit-text-security: disc !important;
}

.invite-to {
  width: 100px;

  & input {
    height: 28px !important;
    font-size: 14px !important;
  }
}

.check-ul {
  padding-inline-start: 4px;
  list-style-type: none;

  & li {
    font-size: 12px;

    &:before {
      content: '\2716';
      color: red;
      padding-right: 4px;
    }
  }
}

.sweet-alert-select {
  background: url(http://cdn0.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
    right/14px no-repeat !important;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background-position-x: 180px !important;
}

.auth-logo {
  padding: 28px 70px 20px;
  position: unset !important;
}

.close-x-button {
  position: relative;
  /* Ensure relative positioning for the parent button */
  background-color: transparent;
  border: 0;
  width: 25px;
  height: 25px;
  padding: 0 !important;
  z-index: 2;

  &:focus {
    outline: none;
  }

  &:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.3);
    border-radius: 50%;
    transform: scale(1.7);
    z-index: -1;
  }

  & i {
    font-size: 34px !important;
    color: rgba(128, 128, 128, 0.85);
    cursor: pointer;
    // margin-top: 5px;
  }
}

.iti {
  width: 100%;
}

.actionsAnim-enter,
.actionsAnim-leave-to {
  opacity: 0;
}

.actionsAnim-enter-active,
.actionsAnim-leave-active {
  transition: opacity 0.15s;
}

.cell-title {
  margin-bottom: 0;
  font-size: 14px;
  // color: #4C5B82 !important;
  // color: #5a5a5a !important;
  color: var(--theme-primary-color-lightness-4-saturation-5) !important;
}

.cell-subtitle {
  color: #909091 !important;
  font-size: 12px;
  white-space: 'nowrap';
  overflow: 'hidden';
}

#disputes > .card-body {
  padding: 0;
}

.card-title-value {
  // color: #4C5B82 !important;
  // color: #5a5a5a !important;
  color: var(--theme-primary-color-lightness-4-saturation-5) !important;
}

/* CUSTOM CHECKBOX STYLES */
input[type='checkbox'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  width: 17px !important;
  height: 17px !important;
  color: var(--theme-primary-color);
  border: 2px solid var(--theme-primary-color);
  border-radius: 0.15em;
  display: grid;
  place-content: center;
  padding-right: 1px !important;
}

.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

input[type='checkbox']::before {
  content: '';
  transform: scale(0);
  width: 14px;
  height: 15px;
  box-shadow: inset 35px 1em var(--theme-primary-color) !important;
  padding: 2px !important;
  clip-path: polygon(28% 40%, 13% 57%, 48% 86%, 94% 29%, 77% 17%, 47% 56%);
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

input[type='checkbox']:disabled {
  transform: scale(1);
  color: #e9ecef;
  border: 2px solid #e9ecef;
}

input[type='checkbox']:disabled:checked::before {
  transform: scale(1);
  box-shadow: inset 35px 1em #e9ecef !important;
  color: #e9ecef;
  border: 2px solid #e9ecef;
}

.default-accepted-card {
  font-weight: unset;
  font-size: 12px;
  padding-top: 0.37rem;
  text-transform: unset;
  height: 26px;
  margin-top: 33px;
  border-radius: 12px;
}

.icon-style {
  font-size: 16px;
  // color: #7589ae !important;
  color: var(--theme-primary-color-lightness-6-saturation-5) !important;
  height: 30px;

  &::before {
    vertical-align: middle;
  }
}

.remove-margin-bottom {
  margin-bottom: 0 !important;

  * {
    margin-bottom: 0 !important;
  }
}

.elipsis input {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
